import React, { FC } from 'react';
import { PageSectionForm } from './PageSectionForm';
import { HeroComponent } from './HeroComponent';
import Box from '@mui/material/Box';
import { ComponentType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { ListingsPage } from '../webView/ListingsPage';
import { ContactPage } from './ContactPage';
import { ReduxPageSection, ReduxWebsitePage } from './redux/websiteData';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import { PhotoGrid } from '../webView/PhotoGrid';

type PageTabProps = {
  pageId: string;
  page: ReduxWebsitePage;
  hidden: boolean;
  editMode?: boolean;
  onPageSectionUpdate?: (pageId: string, section: ReduxPageSection) => void;
  onSubmitPageSection?: (pageId: string, sectionId: string) => void;
  isPropertyPreview?: boolean;
  onClickContactUs?: () => void;
};

export const PageTab: FC<PageTabProps> = ({
  page,
  pageId,
  hidden,
  editMode,
  onPageSectionUpdate,
  onSubmitPageSection,
  isPropertyPreview,
  onClickContactUs,
}) => {
  const theme = useTheme();
  const handlePageSectionUpdate = (section: ReduxPageSection) => {
    onPageSectionUpdate?.(pageId, section);
  };

  return (
    <div aria-label={`${pageId}-tabpanel`} role="tabpanel" hidden={hidden}>
      <Box
        style={{
          overflowY: 'auto',
        }}
      >
        <Box
          display="flex"
          flexGrow={1}
          justifyContent="center"
          p={5}
          sx={{ backgroundColor: pageId === 'contact' ? theme.palette.primary.light : theme.palette.background.paper }}
        >
          <Typography variant="h1" component="h2" sx={{ color: theme.palette.primary.dark }}>
            {page.title}
          </Typography>
        </Box>
        {pageId === 'vacancies' && <ListingsPage isPropertyPreview={isPropertyPreview} />}
        {pageId === 'contact' && <ContactPage isPropertyPreview={isPropertyPreview} />}
        <Box p={1}>
          {page.sections?.map((section, index) =>
            editMode && onSubmitPageSection ? (
              <PageSectionForm
                key={index}
                section={section}
                onChangeSection={handlePageSectionUpdate}
                pageId={pageId}
                onSubmitPageSection={onSubmitPageSection}
              />
            ) : section.component === ComponentType.Hero && onClickContactUs ? (
              <HeroComponent key={index} section={section} onClickContactUs={onClickContactUs} />
            ) : section.websiteProps?.images && section.websiteProps?.images?.length > 0 ? (
              <Box pt={5}>
                <PhotoGrid photos={section.websiteProps?.images ?? []} />
              </Box>
            ) : (
              <Box
                display={'flex'}
                flexGrow={1}
                justifyContent={'center'}
                border={'.1rem solid gray'}
                minHeight={'10rem'}
                borderRadius={1}
                m={1}
                mt={5}
                p={2}
              >
                <Typography>Photo Carousel Here</Typography>
              </Box>
            ),
          )}
        </Box>
      </Box>
    </div>
  );
};
