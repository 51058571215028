import { initInteractable, Interactable } from '@monkeyjump-labs/cam-fe-shared/dist/types/ApiData';
import {
  ICreateEmailToPropertyFromWebsiteHandlerRequest,
  IUpdateWebsitePageHandlerRequest,
  IUpdateWebsitePageSectionHandlerRequest,
  IWebsite,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../../app/store';
import { useSelector } from 'react-redux';
import { mapReduxWebsite, ReduxWebsite } from './websiteData';

export interface WebsiteSlice {
  website: Interactable<ReduxWebsite>;
}

const initialState: WebsiteSlice = {
  website: initInteractable<ReduxWebsite>(),
};

/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection JSUnusedLocalSymbols
export const websiteSlice = createSlice({
  name: 'website',
  initialState,
  reducers: {
    getPropertyWebsiteAction: (state, action: PayloadAction<{ propertyId: string }>) => {
      state.website.loaded = false;
      state.website.loading = true;
    },
    getPropertyWebsiteSuccessAction: (state, action: PayloadAction<IWebsite>) => {
      state.website.loaded = true;
      state.website.loading = false;
      state.website.value = mapReduxWebsite(action.payload);
    },
    getPropertyWebsiteFailAction: (state) => {
      state.website.loaded = false;
      state.website.loading = false;
    },
    updateWebsitePageAction: (
      state,
      action: PayloadAction<{
        websiteId: string;
        body: IUpdateWebsitePageHandlerRequest;
      }>,
    ) => {
      state.website.submitted = false;
      state.website.submitting = true;
    },
    updateWebsitePageSuccessAction: (state, action: PayloadAction<{ body: IUpdateWebsitePageHandlerRequest }>) => {
      state.website.submitted = true;
      state.website.submitting = false;
      if (!state.website.value?.pages || !action.payload.body.pageId) return;
      const updatedPage = state.website.value.pages[action.payload.body.pageId];
      state.website.value = {
        ...state.website.value,
        pages: {
          ...state.website.value?.pages,
          [action.payload.body.pageId]: {
            ...updatedPage,
            title: action.payload.body.title,
            index: action.payload.body.index,
            metadata: action.payload.body.metadata,
            layout: action.payload.body.layout,
          },
        },
      };
    },
    updateWebsitePageFailAction: (state) => {
      state.website.submitted = false;
      state.website.submitting = false;
    },
    updateWebsitePageSectionAction: (
      state,
      action: PayloadAction<{
        websiteId: string;
        body: IUpdateWebsitePageSectionHandlerRequest;
      }>,
    ) => {
      state.website.submitted = false;
      state.website.submitting = true;
    },
    updateWebsitePageSectionSuccessAction: (
      state,
      action: PayloadAction<{ body: IUpdateWebsitePageSectionHandlerRequest }>,
    ) => {
      state.website.submitted = true;
      state.website.submitting = false;
      if (!state.website.value?.pages || !action.payload.body.pageId || !action.payload.body.sectionId) return;
      const updatedPage = state.website.value.pages[action.payload.body.pageId];
      const updatedSections = updatedPage.sections?.map((x) =>
        x.id === action.payload.body.sectionId
          ? {
              ...x,
              websiteProps: {
                title: action.payload.body.title,
                subtitle: action.payload.body.subtitle,
                description: action.payload.body.description,
                styles: action.payload.body.styles,
              },
            }
          : x,
      );
      state.website.value = {
        ...state.website.value,
        pages: {
          ...state.website.value?.pages,
          [action.payload.body.pageId]: {
            ...updatedPage,
            sections: updatedSections,
          },
        },
      };
    },
    updateWebsitePageSectionFailAction: (state) => {
      state.website.submitted = false;
      state.website.submitting = false;
    },
    sendEmailToPropertyFromWebsiteAction: (
      state,
      action: PayloadAction<{
        propertyId: string;
        body: ICreateEmailToPropertyFromWebsiteHandlerRequest;
      }>,
    ) => {
      state.website.submitted = false;
      state.website.submitting = true;
    },
    sendEmailToPropertyFromWebsiteSuccessAction: (state) => {
      state.website.submitted = true;
      state.website.submitting = false;
    },
    sendEmailToPropertyFromWebsiteFailAction: (state) => {
      state.website.submitted = false;
      state.website.submitting = false;
    },
  },
});

export const {
  getPropertyWebsiteAction,
  getPropertyWebsiteSuccessAction,
  getPropertyWebsiteFailAction,
  updateWebsitePageSuccessAction,
  updateWebsitePageFailAction,
  updateWebsitePageAction,
  updateWebsitePageSectionFailAction,
  updateWebsitePageSectionSuccessAction,
  updateWebsitePageSectionAction,
  sendEmailToPropertyFromWebsiteFailAction,
  sendEmailToPropertyFromWebsiteSuccessAction,
  sendEmailToPropertyFromWebsiteAction,
} = websiteSlice.actions;

export const useWebsite = () => useSelector((state: RootState) => state.website);
