import React, { FC } from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { GridTripleDotsVerticalIcon } from '@mui/x-data-grid-premium';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

type PageButtonProps = {
  title: string;
  selected: boolean;
  onCLick: () => void;
};

export const PageButton: FC<PageButtonProps> = ({ selected, onCLick, title }) => {
  const theme = useTheme();
  return (
    <Box
      p={1}
      component={'button'}
      sx={[styles.button, { backgroundColor: selected ? theme.palette.primary.light : 'default' }]}
      onClick={onCLick}
    >
      <Typography>{title}</Typography>
      <Tooltip title={'eventually users can add additional text blocks, images etc here'}>
        <IconButton size="small">
          <GridTripleDotsVerticalIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

const styles = {
  button: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: 'none',
    borderBottom: '1px solid gray',
  },
};
