import React, { FC } from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import {
  AssociationChildType,
  AssociationType,
  ComponentType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import { ReduxPageSection } from './redux/websiteData';
import { DocumentList } from '../../../_shared/documents/components/DocumentList';
import { useWebsite } from './redux/websiteSlice';
import Typography from '@mui/material/Typography';

type HeroFormProps = {
  pageId: string;
  section: ReduxPageSection;
  onChangeSection: (section: ReduxPageSection) => void;
  onSubmitPageSection: (pageId: string, sectionId: string) => void;
};

export const PageSectionForm: FC<HeroFormProps> = ({ pageId, section, onChangeSection, onSubmitPageSection }) => {
  const { website } = useWebsite();
  const { title, description, subtitle } = section.websiteProps ?? {};

  return (
    <Stack p={1} spacing={1}>
      <Stack p={1} mb={3} border={'.1rem solid gray'} borderRadius={1}>
        <Box display={'flex'} flexGrow={1} justifyContent={'center'}>
          <Typography variant={'h6'}>
            {section.component === ComponentType.Hero ? 'Hero Image' : 'Photo Carousel Images'}
          </Typography>
        </Box>
        <DocumentList
          association={{
            associatedId: website.value?.id,
            associationType: AssociationType.Website,
            associationChildType: AssociationChildType.WebsitePageSection,
            associationChildNumber: pageId + '.' + section.id,
          }}
          value={section.websiteProps?.images ?? []}
          uploading={website.submitting}
          propertyId={website.value?.propertyId}
        />
      </Stack>
      {section.component === ComponentType.Hero && (
        <>
          <TextField
            variant="outlined"
            label={'Title'}
            multiline={true}
            rows={2}
            value={title ?? ''}
            onChange={(e) =>
              onChangeSection({ ...section, websiteProps: { ...section.websiteProps, title: e.target.value } })
            }
          />
          <TextField
            variant="outlined"
            label={'Subtitle'}
            multiline={true}
            rows={2}
            value={subtitle ?? ''}
            onChange={(e) =>
              onChangeSection({ ...section, websiteProps: { ...section.websiteProps, subtitle: e.target.value } })
            }
          />
          <TextField
            variant="outlined"
            label={'Description'}
            multiline={true}
            rows={20}
            value={description ?? ''}
            onChange={(e) =>
              onChangeSection({ ...section, websiteProps: { ...section.websiteProps, description: e.target.value } })
            }
          />
          <Box display="flex" justifyContent="flex-end" p={1}>
            <LoadingButton variant={'contained'} onClick={() => section.id && onSubmitPageSection(pageId, section.id)}>
              Save
            </LoadingButton>
          </Box>
        </>
      )}
    </Stack>
  );
};
