import React, { FC, FormEvent } from 'react';
import { useListings } from '../../redux/listingSlice';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { PhoneTextField } from '../../../_shared/texfields/PhoneTextField';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { useDispatch } from 'react-redux';
import { sendEmailToPropertyFromWebsiteAction, useWebsite } from './redux/websiteSlice';
import { toReduxDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import Paper from '@mui/material/Paper';
import { Grid2, useTheme } from '@mui/material';

type ContactPageProps = {
  isPropertyPreview?: boolean;
};

export const ContactPage: FC<ContactPageProps> = ({ isPropertyPreview }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { website } = useWebsite();
  const { propertyAdvertisingDetails } = useListings();
  const { contactInfo, siteManagerContactInfo, siteManagerName } = propertyAdvertisingDetails.value ?? {};
  const cityStateZipString =
    contactInfo?.postalAddress?.city + ', ' + contactInfo?.postalAddress?.state + ' ' + contactInfo?.postalAddress?.zip;
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [moveInDate, setMoveInDate] = React.useState<Date | null>();
  const [message, setMessage] = React.useState('');

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    website.value?.propertyId &&
      dispatch(
        sendEmailToPropertyFromWebsiteAction({
          propertyId: website.value?.propertyId,
          body: {
            name,
            email,
            phone,
            message,
            moveInDate: moveInDate ? toReduxDate(moveInDate) : undefined,
          },
        }),
      );
  };

  return (
    <Grid2
      container
      direction="row"
      sx={{ backgroundColor: theme.palette.primary.light, mt: -4, height: isPropertyPreview ? '100%' : '100vh' }}
    >
      <Grid2
        size={{ xs: 12, md: 12, lg: 12, xl: 6 }}
        width={'50%'}
        p={1}
        justifyContent={'center'}
        // alignContent={'center'}
        sx={{ paddingLeft: '5rem', paddingTop: '5rem' }}
      >
        <Box sx={{ display: 'flex', pb: 2 }}>
          <Typography variant="h4">We'd Love to Hear From You!</Typography>
        </Box>
        <Box sx={{ pb: 2 }}>
          <Typography variant={'h5'}>Give Us a Call</Typography>
          <Typography>
            {contactInfo?.phone ??
              `No Phone number ${isPropertyPreview ? `(request number on property configuration page)` : ''}`}
          </Typography>
        </Box>
        {contactInfo?.postalAddress?.streetAddress1 && (
          <Box sx={{ pb: 2 }}>
            <Typography variant={'h5'}>Our Location</Typography>
            <Typography>{contactInfo?.postalAddress?.streetAddress1}</Typography>
            <Typography>{contactInfo?.postalAddress?.streetAddress2}</Typography>
            <Typography>{cityStateZipString}</Typography>
          </Box>
        )}
        {siteManagerName && (
          <>
            <Typography variant={'h5'}>Site Manager</Typography>
            <Typography>{siteManagerName}</Typography>
            <Typography>{siteManagerContactInfo?.phone}</Typography>
            <Typography>{siteManagerContactInfo?.email}</Typography>
          </>
        )}
      </Grid2>
      <Grid2 size={{ xs: 12, md: 12, lg: 12, xl: 6 }} paddingX={isPropertyPreview ? 5 : 10} py={5}>
        <Paper sx={{ pt: 2, display: 'flex', flexGrow: 1 }}>
          <Stack p={1} margin={10}>
            <Box p={1} display="flex">
              <Typography variant="h5">Get In Touch!</Typography>
            </Box>
            <form onSubmit={handleSubmit}>
              <TextField
                label={'Name'}
                fullWidth={true}
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                label={'Email'}
                fullWidth={true}
                required
                type={'email'}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <PhoneTextField
                label={'Phone'}
                fullWidth={true}
                required
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <DatePicker
                label={'Move-In Date'}
                slotProps={{ textField: { fullWidth: true } }}
                value={moveInDate}
                onChange={(date: Date | null) => setMoveInDate(date)}
              />
              <TextField
                label={'Message'}
                multiline={true}
                rows={5}
                fullWidth={true}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <Box pt={1} display="flex" justifyContent={'right'}>
                <LoadingButton variant={'contained'} type={'submit'} loading={website.submitting}>
                  Submit
                </LoadingButton>
              </Box>
            </form>
          </Stack>
        </Paper>
      </Grid2>
    </Grid2>
  );
};
