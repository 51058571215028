import {
  IPage,
  IPageSection,
  IWebsite,
  IWebsiteProps,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { mapReduxDocument, ReduxDocument } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';

export type ReduxWebsite = Omit<IWebsite, 'logo' | 'pages'> & {
  logo?: ReduxDocument;
  pages?: { [key: string]: ReduxWebsitePage } | undefined;
};

export type ReduxWebsitePage = Omit<IPage, 'sections'> & { sections?: ReduxPageSection[] };

export type ReduxPageSection = Omit<IPageSection, 'websiteProps'> & { websiteProps?: ReduxWebsiteProps };

export type ReduxWebsiteProps = Omit<IWebsiteProps, 'images'> & { images?: ReduxDocument[] };

export function mapReduxWebsite(website: IWebsite): ReduxWebsite {
  return {
    ...website,
    logo: website.logo ? mapReduxDocument(website.logo) : undefined,
    pages: website.pages
      ? Object.fromEntries(
          Object.entries(website.pages).map(([key, page]) => {
            // Assuming mapPageToReduxPage transforms a Page into a ReduxPage
            return [key, mapReduxWebsitePage(page)];
          }),
        )
      : undefined,
  };
}

function mapReduxWebsitePage(page: IPage) {
  return { ...page, sections: page.sections ? page.sections.map(mapReduxWebsitePageSection) : undefined };
}

function mapReduxWebsitePageSection(pageSection: IPageSection) {
  return {
    ...pageSection,
    websiteProps: pageSection.websiteProps ? mapReduxWebsiteProps(pageSection.websiteProps) : undefined,
  };
}

function mapReduxWebsiteProps(websiteProps: IWebsiteProps) {
  return {
    ...websiteProps,
    images: websiteProps.images ? websiteProps.images.map(mapReduxDocument) : [],
  };
}
