import {
  getPropertyWebsiteAction,
  getPropertyWebsiteFailAction,
  getPropertyWebsiteSuccessAction,
  sendEmailToPropertyFromWebsiteAction,
  sendEmailToPropertyFromWebsiteFailAction,
  sendEmailToPropertyFromWebsiteSuccessAction,
  updateWebsitePageAction,
  updateWebsitePageFailAction,
  updateWebsitePageSectionAction,
  updateWebsitePageSectionFailAction,
  updateWebsitePageSectionSuccessAction,
  updateWebsitePageSuccessAction,
} from './websiteSlice';
import { all, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { apiCall, ApiClientSingleton } from '@monkeyjump-labs/cam-fe-shared/dist/services/buildApiClient';
import {
  CreateEmailToPropertyFromWebsiteHandlerRequest,
  GetWebsiteHandlerResponse,
  ICreateEmailToPropertyFromWebsiteHandlerRequest,
  IUpdateWebsitePageHandlerRequest,
  IUpdateWebsitePageSectionHandlerRequest,
  UpdateWebsitePageHandlerRequest,
  UpdateWebsitePageSectionHandlerRequest,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { showErrorAction, showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';

function* getPropertyWebsite(action: PayloadAction<{ propertyId: string }>) {
  try {
    const response: GetWebsiteHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().website_GetWebsiteForProperty,
      action.payload.propertyId,
    );
    if (response) {
      yield put(getPropertyWebsiteSuccessAction(response.toJSON().website));
    }
  } catch (error: any) {
    yield put(getPropertyWebsiteFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'Problem getting property website' }));
  }
}

function* updateWebsitePage(
  action: PayloadAction<{
    websiteId: string;
    body: IUpdateWebsitePageHandlerRequest;
  }>,
) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().website_UpdateWebsitePage,
      action.payload.websiteId,
      new UpdateWebsitePageHandlerRequest(action.payload.body),
    );
    yield put(updateWebsitePageSuccessAction(action.payload));
    yield put(showToastMessageAction({ message: 'Website updated successfully.', severity: 'success' }));
  } catch (error: any) {
    yield put(updateWebsitePageFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'Problem updating website' }));
  }
}

function* updateWebsitePageSection(
  action: PayloadAction<{
    websiteId: string;
    body: IUpdateWebsitePageSectionHandlerRequest;
  }>,
) {
  try {
    console.log(action.payload);
    yield apiCall(
      ApiClientSingleton.getInstance().website_UpdateWebsitePageSection,
      action.payload.websiteId,
      new UpdateWebsitePageSectionHandlerRequest(action.payload.body),
    );
    yield put(updateWebsitePageSectionSuccessAction(action.payload));
    yield put(showToastMessageAction({ message: 'Website updated successfully.', severity: 'success' }));
  } catch (error: any) {
    yield put(updateWebsitePageSectionFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'Problem updating website' }));
  }
}

function* sendEmailToPropertyFromWebsite(
  action: PayloadAction<{ propertyId: string; body: ICreateEmailToPropertyFromWebsiteHandlerRequest }>,
) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().email_SendEmailToPropertyFromWebsite,
      action.payload.propertyId,
      new CreateEmailToPropertyFromWebsiteHandlerRequest(action.payload.body),
    );
    yield put(sendEmailToPropertyFromWebsiteSuccessAction());
    yield put(showToastMessageAction({ message: 'Message sent successfully', severity: 'success' }));
  } catch (error: any) {
    yield put(sendEmailToPropertyFromWebsiteFailAction());
    yield put(
      showErrorAction({ error, fallbackMessage: 'Problem sending email from website contact form, try again later' }),
    );
  }
}

export function* websiteSaga() {
  yield all([
    takeLatest(getPropertyWebsiteAction.type, getPropertyWebsite),
    takeLatest(updateWebsitePageAction.type, updateWebsitePage),
    takeLatest(updateWebsitePageSectionAction.type, updateWebsitePageSection),
    takeLatest(sendEmailToPropertyFromWebsiteAction.type, sendEmailToPropertyFromWebsite),
  ]);
}
