import React, { FC } from 'react';
import { Grid2 } from '@mui/material';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import { PageTab } from './PageTab';
import Stack from '@mui/material/Stack';
import { ReduxPageSection, ReduxWebsite } from './redux/websiteData';
import Divider from '@mui/material/Divider';

type WebsiteProps = {
  website?: ReduxWebsite;
  setSelectedTab: (selectedTab: number) => void;
  selectedTab: number;
  editMode?: boolean;
  isPreview?: boolean;
  onPageSectionUpdate?: (pageId: string, section: ReduxPageSection) => void;
  onSubmitPageSection?: (pageId: string, sectionId: string) => void;
  onClickContactUs?: () => void;
};

export const Website: FC<WebsiteProps> = ({
  website,
  setSelectedTab,
  selectedTab,
  editMode,
  onPageSectionUpdate,
  onSubmitPageSection,
  onClickContactUs,
  isPreview,
}) => {
  const pageEntries = Object.entries(website?.pages ?? {}).sort(
    ([, aValue], [, bValue]) => (aValue?.index ?? 0) - (bValue?.index ?? 0),
  );
  return (
    <Stack sx={{ backgroundColor: 'white', pb: 10 }}>
      <Grid2 container p={1}>
        <Grid2 size={{ xs: 12, md: 12, lg: 12, xl: isPreview ? 12 : 2 }}>
          <img
            src={website?.logo?.uri ?? '../../../../smartManagementLogo@2xCopy.png'}
            alt={'property logo'}
            style={{ maxHeight: '50px' }}
          />
        </Grid2>
        <Grid2 size={{ xs: 12, md: 12, lg: 12, xl: isPreview ? 12 : 10 }}>
          <Box sx={{ borderBottom: 1, borderColor: 'white', display: 'flex', p: 1 }}>
            <Tabs
              value={selectedTab}
              onChange={(event, value) => setSelectedTab(value)}
              aria-label="website tabs"
              sx={{ marginLeft: 'auto' }}
            >
              {pageEntries.map(([pageId, page], index) => (
                <Tab
                  key={pageId}
                  label={page.title}
                  value={index}
                  id={`${pageId}-tab`}
                  aria-controls={`${pageId}-tabpanel`}
                  onClick={() => {}}
                />
              ))}
              <Button
                component="a"
                href={`${process.env.REACT_APP_WEB_URL}/tenantPortal`}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Open the Current Residents portal in a new browser tab"
                variant="outlined"
                sx={{ ml: 1, minWidth: 150 }}
              >
                Current Residents
              </Button>
            </Tabs>
          </Box>
        </Grid2>
      </Grid2>
      <Divider />
      {pageEntries.map(([pageId, page], index) => (
        <PageTab
          key={pageId}
          pageId={pageId}
          page={page}
          hidden={selectedTab !== index}
          editMode={editMode}
          onPageSectionUpdate={onPageSectionUpdate}
          onSubmitPageSection={onSubmitPageSection}
          isPropertyPreview={isPreview}
          onClickContactUs={onClickContactUs}
        />
      ))}
    </Stack>
  );
};
