import React, { FC } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import { FileUploadButton } from '../../../_shared/buttons/FileUploadButton';
import AddIcon from '@mui/icons-material/Add';
import { PageButton } from './PageButton';
import Tooltip from '@mui/material/Tooltip';
import { AssociationType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useDispatch } from 'react-redux';
import { addDocumentToTypeAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/documents/documentSlice';
import { ReduxWebsite } from './redux/websiteData';

type PrimaryBuilderMenuProps = {
  selectedTab: number;
  setSelectedTab: (selectedTab: number) => void;
  data?: ReduxWebsite;
  onChangeData?: (data: ReduxWebsite) => void;
};

export const PrimaryBuilderMenu: FC<PrimaryBuilderMenuProps> = ({ selectedTab, setSelectedTab, data }) => {
  const dispatch = useDispatch();

  const pageEntries = Object.entries(data?.pages ?? {}).sort(
    ([, aValue], [, bValue]) => (aValue?.index ?? 0) - (bValue?.index ?? 0),
  );
  return (
    <Stack>
      <Box
        sx={{
          flexGrow: 1,
          borderBottom: '1px solid gray',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={styles.header}>
          <IconButton>
            <FileCopyIcon />
          </IconButton>
          <Typography variant={'h5'}>Pages</Typography>
          <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'right' }}>
            <Tooltip title={'eventually users can add pages here'}>
              <IconButton>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      {pageEntries.map(([pageId, page], index) => (
        <PageButton
          key={`${pageId}-${index}`}
          selected={selectedTab === index}
          title={page.title ?? pageId}
          onCLick={() => setSelectedTab(index)}
        />
      ))}

      <Divider />
      <Stack>
        <Box sx={styles.header}>
          <IconButton>
            <FormatColorFillIcon />
          </IconButton>
          <Typography variant={'h5'}>Styles</Typography>
        </Box>
        <Stack p={1} spacing={1}>
          {/*<Stack spacing={1} direction={'row'}>*/}
          {/*  <ColorCell*/}
          {/*    onChange={() => {}}*/}
          {/*    color={theme.palette.primary.main}*/}
          {/*    onSubmit={() => {*/}
          {/*      dispatch(endpointIsntIntegratedAction());*/}
          {/*    }}*/}
          {/*  />*/}
          {/*  <Typography>Primary</Typography>*/}
          {/*</Stack>*/}
          {/*<Stack spacing={1} direction={'row'}>*/}
          {/*  <ColorCell*/}
          {/*    onChange={() => {}}*/}
          {/*    color={theme.palette.secondary.main}*/}
          {/*    onSubmit={() => {*/}
          {/*      dispatch(endpointIsntIntegratedAction());*/}
          {/*    }}*/}
          {/*  />*/}
          {/*  <Typography>Secondary</Typography>*/}
          {/*</Stack>*/}
          <FileUploadButton
            onUpload={(file: File) => {
              data?.id &&
                data?.propertyId &&
                dispatch(
                  addDocumentToTypeAction({
                    associatedId: data?.id,
                    type: AssociationType.Website,
                    file: file,
                    propertyId: data?.propertyId,
                  }),
                );
            }}
          >
            Add Logo
          </FileUploadButton>
        </Stack>
      </Stack>
    </Stack>
  );
};

const styles = {
  header: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: '2px solid gray',
    pt: 2,
  },
};
