import React, { useEffect } from 'react';
import { Website } from './Website';
import { getPropertyWebsiteAction, useWebsite } from './redux/websiteSlice';
import { getPropertyAdvertisingDetailsAction } from '../../redux/listingSlice';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

export const PropertyWebsite = () => {
  const { propertyId } = useParams();
  const dispatch = useDispatch();
  const { website } = useWebsite();
  const [selectedTab, setSelectedTab] = React.useState(0);

  useEffect(() => {
    if (!propertyId) return;
    dispatch(getPropertyWebsiteAction({ propertyId: propertyId }));
    dispatch(getPropertyAdvertisingDetailsAction(propertyId));
  }, [propertyId]);

  const pageEntries = Object.entries(website.value?.pages ?? {}).sort(
    ([, aValue], [, bValue]) => (aValue?.index ?? 0) - (bValue?.index ?? 0),
  );

  const handleClickContactUs = () => {
    const indexOfContactTab = pageEntries.findIndex(([pageId]) => pageId === 'contact');
    setSelectedTab(indexOfContactTab);
  };

  return (
    <Website
      website={website.value}
      setSelectedTab={(number) => setSelectedTab(number)}
      selectedTab={selectedTab}
      onClickContactUs={handleClickContactUs}
    />
  );
};
