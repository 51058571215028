import React, { FC, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import { Grid2 } from '@mui/material';
import Box from '@mui/material/Box';
import { PrimaryBuilderMenu } from './PrimaryBuilderMenu';
import { StyledInfoBox } from '../../../_shared/styledComponents/StyledInfoBox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { getPropertyWebsiteAction, updateWebsitePageSectionAction, useWebsite } from './redux/websiteSlice';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { useDispatch } from 'react-redux';
import { getPropertyAdvertisingDetailsAction } from '../../redux/listingSlice';
import { showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { ReduxPageSection, ReduxWebsite } from './redux/websiteData';
import { Website } from './Website';

export const WebsiteBuilder: FC = () => {
  const dispatch = useDispatch();
  const { website } = useWebsite();
  const { selectedContext } = useAssets();
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [data, setData] = React.useState<ReduxWebsite | undefined>(website.value);
  const [editMode, setEditMode] = React.useState(false);

  const pageEntries = Object.entries(data?.pages ?? {}).sort(
    ([, aValue], [, bValue]) => (aValue?.index ?? 0) - (bValue?.index ?? 0),
  );

  useEffect(() => {
    if (!selectedContext.propertyId) return;
    dispatch(getPropertyWebsiteAction({ propertyId: selectedContext.propertyId }));
    dispatch(getPropertyAdvertisingDetailsAction(selectedContext.propertyId));
  }, [selectedContext.propertyId]);

  useEffect(() => {
    setData(website.value);
  }, [website.value]);

  const handleClickContactUs = () => {
    const indexOfContactTab = pageEntries.findIndex(([pageId]) => pageId === 'contact');
    setSelectedTab(indexOfContactTab);
  };

  const handlePageSectionUpdate = (pageId: string, section: ReduxPageSection) => {
    if (!data?.pages) return;
    const updatedPage = data.pages[pageId];
    setData({
      ...data,
      pages: {
        ...data?.pages,
        [pageId]: { ...updatedPage, sections: updatedPage.sections?.map((x) => (x.id === section.id ? section : x)) },
      },
    });
  };

  // const handleSubmitPage = (pageId: string) => {
  //   website.value?.id &&
  //     data?.pages &&
  //     data.pages[pageId] &&
  //     dispatch(
  //       updateWebsitePageAction({
  //         websiteId: website.value?.id,
  //         body: {
  //           pageId: pageId,
  //           ...data?.pages[pageId],
  //         },
  //       }),
  //     );
  // };

  const handleSubmitPageSection = (pageId: string, sectionId: string) => {
    if (!data?.pages) return;
    const updatedSection = data.pages[pageId].sections?.find((x) => x.id === sectionId);
    if (!updatedSection || !website.value?.id || !data.pages[pageId]) {
      dispatch(showToastMessageAction({ message: 'something is missing' }));
      return;
    }
    dispatch(
      updateWebsitePageSectionAction({
        websiteId: website.value?.id,
        body: {
          pageId: pageId,
          sectionId: sectionId,
          title: updatedSection.websiteProps?.title,
          subtitle: updatedSection.websiteProps?.subtitle,
          styles: updatedSection.websiteProps?.styles,
          description: updatedSection.websiteProps?.description,
        },
      }),
    );
  };

  return (
    <StyledInfoBox label={'Website Builder'}>
      <Stack>
        <Box sx={{ flexGrow: 1, pb: 2, pl: 2 }}>
          <FormGroup sx={{ mt: 1 }}>
            <FormControlLabel
              control={<Switch size="small" checked={editMode} onChange={() => setEditMode(!editMode)} />}
              label={'Edit Mode'}
            />
          </FormGroup>
        </Box>
        <Grid2 container rowSpacing={1}>
          <Grid2 size={{ xs: 3, md: 3, lg: 3, xl: 2 }} sx={{ border: '1px solid gray' }}>
            <PrimaryBuilderMenu
              selectedTab={selectedTab}
              setSelectedTab={(tab: number) => setSelectedTab(tab)}
              data={data}
              onChangeData={() => {}}
            />
          </Grid2>
          <Grid2 size={{ xs: 9, md: 9, lg: 9, xl: 10 }} sx={{ border: '1px solid gray' }}>
            <Website
              website={data}
              setSelectedTab={(tab: number) => setSelectedTab(tab)}
              selectedTab={selectedTab}
              editMode={editMode}
              onPageSectionUpdate={handlePageSectionUpdate}
              onSubmitPageSection={handleSubmitPageSection}
              onClickContactUs={handleClickContactUs}
              isPreview={true}
            />
          </Grid2>
        </Grid2>
      </Stack>
    </StyledInfoBox>
  );
};
