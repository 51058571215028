import React, { FC } from 'react';
import List from '@mui/material/List';
import { FileUploadButton } from '../../buttons/FileUploadButton';
import { ReduxDocument } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { DocumentLink } from './DocumentLink';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import {
  AssociationType,
  ICamAssociation,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useDispatch } from 'react-redux';
import {
  add360PhotoToTypeAction,
  addDocumentToTypeAction,
  removeDocumentFromTypeAction,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/documents/documentSlice';

export type DocumentListProps = {
  propertyId?: string;
  association: ICamAssociation;
  value: ReduxDocument[];
  uploading?: boolean;
  showAsIcons?: boolean;
};

export const imageExtensionTypes = ['jpg', 'jpeg', 'png', 'gif'];

export const DocumentList: FC<DocumentListProps> = ({ propertyId, association, value, showAsIcons, uploading }) => {
  const dispatch = useDispatch();

  const handleDocumentUpload = (file: File) => {
    if (!association.associatedId || !association.associationType) return;
    dispatch(
      addDocumentToTypeAction({
        file: file,
        type: association.associationType,
        associatedId: association.associatedId,
        childType: association.associationChildType,
        childNumber: association.associationChildNumber,
        propertyId: propertyId,
      }),
    );
  };

  const handle360Upload = (files: File[]) => {
    if (!association.associatedId || !association.associationType) return;
    dispatch(
      add360PhotoToTypeAction({
        files: files,
        type: association.associationType,
        associatedId: association.associatedId,
        childType: association.associationChildType,
        childNumber: association.associationChildNumber,
        propertyId: propertyId,
      }),
    );
  };

  const handleDeleteDocument = (id: string) => {
    dispatch(removeDocumentFromTypeAction({ documentId: id, association: association, propertyId }));
  };

  const enable360Upload =
    association.associationType === AssociationType.Task ||
    association.associationType === AssociationType.ScheduledTask ||
    association.associationType === AssociationType.Detail ||
    association.associationType === AssociationType.Listing;

  return (
    <>
      {showAsIcons ? (
        <>
          <Stack flexDirection={'row'}>
            <FileUploadButton onUpload={handleDocumentUpload} loading={uploading} isIcon={true}>
              Upload File
            </FileUploadButton>
            {value.map((doc) => (
              <DocumentLink
                propertyId={propertyId}
                key={doc.id ?? doc.uri}
                association={association}
                document={doc}
                showAsIcons={showAsIcons}
              />
            ))}
          </Stack>
        </>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <Stack direction={'row'} spacing={1}>
              <FileUploadButton onUpload={handleDocumentUpload} multiple={false} loading={uploading}>
                Upload File
              </FileUploadButton>
              {enable360Upload && (
                <FileUploadButton onUpload={handle360Upload} multiple={true} loading={uploading}>
                  Upload 360 Image
                </FileUploadButton>
              )}
            </Stack>
            <List sx={{ width: '100%' }}>
              {value.map((doc) => (
                <DocumentLink
                  propertyId={propertyId}
                  key={doc.id ?? doc.uri}
                  association={association}
                  document={doc}
                  showAsIcons={showAsIcons}
                  onDeleteDocument={handleDeleteDocument}
                />
              ))}
            </List>
          </Grid>
        </Grid>
      )}
    </>
  );
};
