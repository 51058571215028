import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { AddApplicantDialog } from '../../../property/components/applications/applicationDialogs/AddApplicantDialog';
import { useWebsite } from './redux/websiteSlice';
import { ReduxPageSection } from './redux/websiteData';

type HeroComponentProps = {
  section: ReduxPageSection;
  onClickContactUs: () => void;
};

export const HeroComponent: FC<HeroComponentProps> = ({ section, onClickContactUs }) => {
  const { title, description, images, subtitle } = section.websiteProps ?? {};
  const { website } = useWebsite();
  const [open, setOpen] = React.useState(false);
  const propertyId = website.value?.propertyId;

  const descriptionSections = description?.split('\n');

  return (
    <Stack>
      {images && images?.length > 0 ? (
        <img
          src={images[0].uri}
          alt={title}
          style={{ width: '100%', objectFit: 'cover', maxHeight: 'calc(100vh - 200px)' }}
        />
      ) : (
        <Box
          display={'flex'}
          flexGrow={1}
          justifyContent={'center'}
          border={'.1rem solid gray'}
          minHeight={'10rem'}
          borderRadius={1}
          m={1}
          p={2}
        >
          <Typography>Hero Image Here</Typography>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '10px',
          flexDirection: 'column',
        }}
      >
        <Box
          height={'100px'}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px',
          }}
        >
          <Typography variant={'h1'}>{title}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingBottom: '20px',
          }}
        >
          <Typography variant={'h4'} align={'center'}>
            {subtitle}
          </Typography>
        </Box>
        {descriptionSections?.map((section, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingBottom: '20px',
            }}
          >
            <Typography variant={'h6'} align={'center'}>
              {section}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box p={1} display={'flex'} flexGrow={1} justifyContent={'center'} alignItems={'center'}>
        <Button variant={'outlined'} sx={{ marginRight: '10px' }} onClick={onClickContactUs}>
          Contact Us
        </Button>
        <Button variant={'contained'} onClick={() => setOpen(true)}>
          Apply Now!
        </Button>
      </Box>
      <AddApplicantDialog propertyId={propertyId} open={open} onClose={() => setOpen(false)} isWebView={true} />
    </Stack>
  );
};
